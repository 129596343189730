
import { defineComponent, onMounted, ref } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import AuthService from '@/services/auth.service';

import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import UserService from '@/services/user.service';
import Store from '@/store';
import ProjectService from '@/services/project.service';
import TaskStatusService from '@/services/task-status.service';

export default defineComponent({
	components: {
		SVG,
		Loader,
	},

	setup() {
		const router = useRouter();
		const route = useRoute();

		const loading = ref(true);
		const errorMessage = ref('');

		const uuid: string = Array.isArray(route.params.uuid) ? route.params.uuid[0] : route.params.uuid;

		onMounted(async () => {
			await AuthService.loginViaUserLink(uuid).then(async (response) => {
				if (response.status === 200) {
					const user = await AuthService.me();

					if (user.can('view users')) {
						await UserService.get().then((response) => {
							if (response) {
								Store.set('users', response);
							}
						});
					}

					if (user.can('view projects')) {
						await ProjectService.get().then((response) => {
							if (response) {
								Store.set('projects', response);
							}
						});
					}

					router.push({ name: 'Projects' });
					return;
				}

				errorMessage.value = response.data.message;
				loading.value = false;
			});
		});

		return {
			loading,
			errorMessage,
		};
	},
});
